<template>
  <div class="page profile">

      <Hero :data="heroContent" :isProfile="true" class="profile__hero" />
      
      <section class="container profile__content" v-if="content">
          
          <!--<h2>{{ $i18n.messages[$i18n.locale].concepts.title }}</h2>-->

          <div class="profile__content__bio" v-html="content.description"></div>

          <div class="masonry-with-columns" v-if="content.pictures != null">
            <img v-for="item in content.pictures" :key="item.id" :src="'/uploads/'+item" alt="" />
          </div>

      </section>
      
  </div>
</template>

<script>
import Hero from '../components/Hero.vue'
import axios from 'axios'
import { useRoute } from 'vue-router'

export default {
  name: 'Concept',
  components: {
    Hero
  },
  data () {
    return {
      content : null,
      heroContent : null
    }
  },
  watch: {
    '$i18n.locale': {
      handler: function() {
        this.getProfile(this.$route.params.url)
      },
      deep: true
    }
  },
  methods: {
    getProfile(url) {

      const qs = require('qs');
      const query = qs.stringify({
        locale: this.$i18n.locale,
        populate : '*',
        filters: {
          url: {
            $eq: url,
          }
        },
      }, {
        encodeValuesOnly: true, // prettify url
      });

      if (this.apiMode) {

        let apiUrl = this.apiURLBase+'/api/concepts?'+query;
        axios.get(apiUrl).then(response => {
          this.content = response.data.data[0];
          console.log(this.content);
          this.heroContent = {
            "title" : this.content.name,
            "subtitle" : false,
            "image" : this.content.cover.data.url
          };
        });

      } else {

        this.conceptsData[this.$i18n.locale].forEach(row => {
          if(row.url == url) {
            this.content = row;
            this.heroContent = {
              "title" : this.content.name,
              "subtitle" : false,
              "image" : this.content.cover
            };
          } // endif
        });

      }

      

    },
  },  
  mounted () {
    
    const route = useRoute();
    this.getProfile(route.params.url);

    this.$watch(
      () => this.$route.params, () => {
        // react to route changes...
        this.getProfile(this.$route.params.url);
        window.scrollTo(0, 0);
      }
    )
  },
}
</script>

<style lang="scss" scoped>

  @import "../styles/variables";

  .profile {
    
    &__hero .hero_image__content {
    
      margin: 30px 0px;
      max-width: 440px;

      h1 { 
        text-align: left !important; 
        font-size: 100px;
        line-height: 0.9em;
      }

    }

    &__content {

      h2 {

        text-transform: uppercase;
        font-size: 45px;
        text-align: center;

      }

      &__bio {

        line-height: 1.4em;
        font-size: 16px;
        display: block;
        margin: 40px auto;
        width: 100%;
        max-width: 900px;

      }

    }

    &__more_arists {

      background: $highlight-color;
      color: #fff;
      padding: 120px 0px;
      margin: 100px 0px 60px;
      clip-path: polygon(0 6%, 100% 0, 100% 94%, 0 100%);


      h3 {

        text-align: center;
        font-size: 36px;
        text-transform: uppercase;
        color: #000;
        font-weight: 900;
        margin-bottom: 32px;

      }
    }

  }

  .masonry-with-columns {
    margin: 40px auto;
    width: 78%;
    img {
      border: none;
    }
  }

</style>
