<template>
  <section class="container full_width hero_image" v-if="data" :style="[!isProfile ? { backgroundImage: 'url('+ require('../assets/images/'+data.image) + ')' } : { backgroundImage: 'url(/uploads/'+data.image+ ')' }]">
    
      <!-- Dynamic Contet -->
      <div class="hero_image__content">
        <img class="hero_image__content__logo" src="@/assets/images/rumblefish_white_logo.svg" alt="Rumble Fish Logo" v-if="!isProfile" />
        <h1 class="hero_image__content__title" v-html="data.title" v-if="isProfile" />
        <p class="hero_image__content__subtitle" v-if="data.subtitle" v-html="data.subtitle"></p>
      </div>
      <!-- / Dynamic Contet -->

      <!-- Bottom Line Decorator -->
      <div class="hero_image__bottom_gradient"></div>

  </section>
</template>

<script>
export default {
  name: 'Hero',
  props: {
    data: Object,
    isProfile: Boolean
  },
}
</script>

<style lang="scss" scoped>

    .hero_image {

        position: relative;
        min-height: 450px;
        padding: 80px 8%;
        text-align: center;
        background-size: cover;
        margin-bottom: 50px;
        display: flex;
        background-position: center center;

        @media (min-width: 1441px) { 
          min-height: 600px;
        }

        &__content {

            width: 90%;
            max-width: 760px;
            margin: 40px auto;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h1 {

                font-size: 50px;
                text-transform: uppercase;
                font-weight: 900;
                text-align: center;
                margin: 0px auto 20px;

                @media (min-width: 1280px) { 
                  font-size: 100px;
                }

            }

            &__subtitle {

                font-weight: 400;
                font-size: 27px;
                text-align: center;
                text-transform: uppercase;

            }

            &__logo {

              width: 70%;
              max-width: 460px;
              display: block;
              margin: 0px auto 30px;

            }

        }

        &__bottom_gradient {

            width: 100%;
            height: 50px;
            position: absolute;
            left: 0px;
            bottom: 0px;
            z-index: 5;

            background: rgb(0,0,0);
            background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 13%, rgba(0,0,0,0) 96%);
            background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 13%, rgba(0,0,0,0) 96%);
            background: linear-gradient(0deg, rgba(0,0,0,1) 13%, rgba(0,0,0,0) 96%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);

        }
    }

</style>
