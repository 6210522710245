<template>
  <div class="page">

      <Hero :data="$i18n.messages[$i18n.locale].home.hero" />
      
      <div class="container content_wrapper">
          
          <Category :data="artists" :title="$i18n.messages[$i18n.locale].home.artists.title" :path="$i18n.messages[$i18n.locale].home.artists.url" :content="$i18n.messages[$i18n.locale].home.artists" />
          <!--<a :href="lang.path+homeData[lang.iso].artists.url" class="btn big_btn">{{ homeData[lang.iso].artists.btn }}</a>-->

      </div>      

      <div class="container content_wrapper">
          <Category :data="concepts" :title="$i18n.messages[$i18n.locale].home.concepts.title" :path="$i18n.messages[$i18n.locale].home.concepts.url" :content="$i18n.messages[$i18n.locale].home.concepts" />
          <!--<a :href="lang.path+homeData[lang.iso].concepts.url" class="btn big_btn">{{ homeData[lang.iso].concepts.btn }}</a>-->
      </div>

      <section class="container content_wrapper">
        
        <h1 v-html="$i18n.messages[$i18n.locale].about.title" class="page__title"></h1>

        <div class="page__content">
            <p v-for="item in $i18n.messages[$i18n.locale].about.content" :key="item" v-html="item"></p>
        </div>

      </section>
      
  </div>
</template>

<script>
import Hero from '../components/Hero.vue'
import Category from '../components/Category.vue'
import axios from 'axios'

export default {
  name: 'Home',
  components: {
    Hero,
    Category
  },
  data () {
    return {
      artists : null,
      concepts : null
    }
  },
  mounted () {

    if (this.apiMode) {

      // Get artists
      axios.get(this.apiURLBase+'/api/artists?pagination[page]=1&sort[0]=name&populate=*').then(response => {
        this.artists = response.data.data;
      });

      // Get concepts
      axios.get(this.apiURLBase+'/api/concepts?pagination[page]=1&sort[0]=name&populate=*').then(response => {
        this.concepts = response.data.data;
      });

    } else {

      this.artists = this.sortABC(this.artistsData[this.$i18n.locale]);
      this.concepts = this.conceptsData[this.$i18n.locale];

    } // endif
  
  }
}
</script>

<style lang="scss" scoped>
  .content_wrapper {
    text-align: center;
    margin: 120px auto;
    display: block;
  }

  .page__title {

    font-size: 40px;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;  

    @media screen and (min-width: 781px) {
        font-size: 60px;
    }
  }
</style>
