<template>
  <div class="page profile">

      <Hero :data="heroContent" :isProfile="true" class="profile__hero" />
      
      <section class="container profile__content" v-if="content">
          
          <!--<h2>{{ $i18n.messages[$i18n.locale].profile.bio }}</h2>-->

          <div class="profile__content__bio" v-html="content.bio"></div>

      </section>
      


      <section class="container profile__social" v-if="content">

        <a v-if="content.website" href="content.website" target="_blank" class="btn big_btn">{{ $i18n.messages[$i18n.locale].profile.website }}</a>

        <!-- Social Links -->
        <ul class="profile__social__list">

            <li v-if="content.facebook">
              <a :href="content.facebook" target="_blank">
                <img src="@/assets/images/facebook-icon.svg" alt="Facebook" />
              </a>
            </li>

            <li v-if="content.instagram">
              <a :href="content.instagram" target="_blank">
                <img src="@/assets/images/instagram-icon.svg" alt="Instagram" />
              </a>
            </li>

            <li v-if="content.youtube">
              <a :href="content.youtube" target="_blank">
                <img src="@/assets/images/youtube-icon.svg" alt="Youtube" />
              </a>
            </li>

            <!--
            <li v-if="content.twitter">
              <a :href="content.twitter" target="_blank">
                <img src="@/assets/images/twitter-icon.svg" alt="Twitter" />
              </a>
            </li>
            -->

            <li v-if="content.spotify">
              <a :href="content.spotify" target="_blank">
                <img src="@/assets/images/spotify-icon.svg" alt="spotify" />
              </a>
            </li>

            <li v-if="content.soundcloud">
              <a :href="content.soundcloud" target="_blank">
                <img src="@/assets/images/soundcloud-icon.svg" alt="soundcloud" />
              </a>
            </li>

            

        </ul>
        <!-- /Social Links -->

      </section>

      <!-- Territory -->
      <section class="container profile__territory" v-if="content">
        <h3 v-if="content.territory">{{ $i18n.messages[$i18n.locale].profile.territory }}</h3>
        <p class="profile__territory__text">{{ content.territory }}</p>
      </section>
      <!-- /Territory -->

      <section class="container profile__videos" v-if="content">

          <!-- Videos -->
          <div class="video_wrapper" v-if="content.video">
            <div class="video_wrapper__responsive">
              <iframe :src="getYTEmbedURL(content.video)" 
                      title="YouTube Video" frameborder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                      allowfullscreen>
              </iframe>
            </div>
          </div>

          <div class="video_wrapper" v-if="content.video_2">
            <div class="video_wrapper__responsive">
              <iframe :src="getYTEmbedURL(content.video_2)" 
                      title="YouTube Video" frameborder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                      allowfullscreen>
              </iframe>
            </div>
          </div>

      </section>

      <section class="full_width profile__more_arists">
        <div class="container">

          <h3>{{ $i18n.messages[$i18n.locale].profile.more }}</h3>
          <Carousel :data="moreArtist" />
          
        </div>
      </section>

      <FloatingContact v-if="content" :profile="content.name" />
      
  </div>
</template>

<script>
import Hero from '../components/Hero.vue'
import Carousel from '../components/Carousel.vue'
import FloatingContact from '../components/FloatingContact.vue'
import axios from 'axios'
import { useRoute } from 'vue-router'

export default {
  name: 'Profile',
  components: {
    Hero,
    Carousel,
    FloatingContact
  },
  data () {
    return {
      content : null,
      heroContent : null,
      moreArtist : null
    }
  },
  watch: {
    '$i18n.locale': {
      handler: function() {
        this.getProfile(this.$route.params.url)
      },
      deep: true
    }
  },
  methods: {
    getYTEmbedURL(url) {
      let embed_code = url.substring(url.lastIndexOf('/') + 1);
      return "https://www.youtube.com/embed/"+embed_code;
    },
    getProfile(url) {

      const qs = require('qs');
      const query = qs.stringify({
        locale: this.$i18n.locale,
        populate : '*',
        filters: {
          url: {
            $eq: url,
          }
        },
      }, {
        encodeValuesOnly: true, // prettify url
      });

      if(this.apiMode) {

        let apiUrl = this.apiURLBase+'/api/artists?'+query;

        axios.get(apiUrl).then(response => {
          this.content = response.data.data[0];
          this.heroContent = {
            "title" : this.content.name,
            "subtitle" : false,
            "image" : this.content.cover.data.url
          };
        });

      } else {

        this.artistsData[this.$i18n.locale].forEach(row => {
          if(row.url == url) {
            this.content = row;
            this.heroContent = {
              "title" : this.content.name,
              "subtitle" : false,
              "image" : this.content.cover
            };
          } // endif
        });

      } // endif     

    },

    getMoreArtists(url) {

      if(this.apiMode) {
        let apiUrl = this.apiURLBase+'/api/artists?filters[url][$ne]='+url+'&sort[0]=name&populate=*';
        axios.get(apiUrl).then(response => {
          this.moreArtist = response.data.data;
        });
      } else {
        this.moreArtist = this.sortABC(this.artistsData[this.$i18n.locale]);
      }

    }
  },  
  mounted () {
    
    console.log(this.$i18n.locale);
    const route = useRoute();
    this.getProfile(route.params.url);
    this.getMoreArtists(this.$route.params.url);

    this.$watch(
      () => this.$route.params, () => {
        // react to route changes...
        this.getProfile(this.$route.params.url);
        this.getMoreArtists(this.$route.params.url);
      }
    )
  },

 
}
</script>

<style lang="scss">

  @import "../styles/variables";

  .profile {
    
    &__hero .hero_image__content {
    
      margin: 30px 0px;
      max-width: 440px;

      h1 { 
        text-align: left !important; 
        font-size: 55px;
        line-height: 0.9em;

        @media screen and (min-width: 781px) {
          font-size: 70px;
        }

        @media (min-width: 1280px) { 
          font-size: 100px;
        }
      }

    }

    &__content {

      h2 {

        text-transform: uppercase;
        font-size: 45px;
        text-align: center;

      }

      &__bio {

        line-height: 1.4em;
        font-size: 16px;
        display: block;
        margin: 40px auto;
        width: 100%;
        max-width: 900px;

      }

    }

    &__social {

      text-align: center;
      margin: 60px auto;

      &__list {

        margin: 60px auto;
        padding: 0px;
        display: block;
        text-align: center;

        li {
          
          list-style-type: none;
          display: inline-block;
          vertical-align: top;
          margin: 15px;
          width: 45px;

          img {
            width: 100%;
          }

        }
      }

    }

    &__territory {
      max-width: 900px;
      display: block;
      text-align: center;

      h3 {
        font-size: 22px;
        text-transform: uppercase;
        margin-bottom: 2px;
      }

      &__text {
        font-size: 22px;
        font-weight: 900;
        text-transform: uppercase;
        color: $highlight-color;
        letter-spacing: 2px;

      }
    }

    &__more_arists {

      background: $highlight-color;
      color: #fff;
      padding: 120px 0px;
      margin: 100px 0px 60px;
      clip-path: polygon(0 6%, 100% 0, 100% 94%, 0 100%);


      h3 {

        text-align: center;
        font-size: 36px;
        text-transform: uppercase;
        color: #000;
        font-weight: 900;
        margin-bottom: 32px;

      }
    }

  }

  .video_wrapper {

    display: block;
    width: 100%;
    max-width: 800px;
    margin: 60px auto;
    border: 5px solid $highlight-color;

    &__responsive {
      height: 0;
      overflow: hidden;
      padding-bottom: 56.25%;
      padding-top: 30px;
      position: relative;
      
      iframe, .video-responsive object, .video-responsive embed {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

</style>
