import { createApp } from 'vue'
import App from './App.vue'
import { createI18n } from 'vue-i18n'
import {createRouter, createWebHistory} from "vue-router";

// import { createMetaManager } from 'vue-meta'
import "./styles/app.scss";

// Localization
// Data from JSONs
import homeData from "./data/home.json";
import contactData from "./data/contact.json";
import aboutData from "./data/about.json";
import profileData from "./data/profile.json";
import conceptData from "./data/concept.json";
import navData from "./data/nav.json";
import footerData from "./data/footer.json";

// Data integrated
import artistsData from "./data/artists_data.json";
import conceptsData from "./data/concepts_data.json";

// vue-i18n options
const i18n = createI18n({
    locale: getRouteLocale(window.location.pathname),
    messages: {
        es : {
            home : homeData.es,
            nav : navData.es,
            profile : profileData.es,
            concepts : conceptData.es,
            about : aboutData.es,
            contact : contactData.es,
            footer : footerData.es
        },
        en : {
            home : homeData.en,
            nav : navData.en,
            profile : profileData.en,
            concepts : conceptData.en,
            about : aboutData.en,
            contact : contactData.en,
            footer : footerData.en
        }
    },
});

// Router Views
import Home from './views/Home.vue'
import About from './views/About.vue'
import Contact from './views/Contact.vue'
import Profile from './views/Profile.vue'
import CategoryPage from './views/Category.vue'
import Concept from './views/Concept.vue'

const routes = [
    {
        path: '/',
        name: 'Home Es',
        component: Home
    },
    {
        path: '/en/',
        name: 'Home En',
        component: Home
    },
    {
        path: '/artistas',
        name: 'Artistas',
        component: CategoryPage
    },
    {
        path: '/en/artists',
        name: 'Artists En',
        component: CategoryPage
    },
    { 
        path: '/artistas/:url', 
        name: 'Artistas Perfil',
        component: Profile,
        props: true
    },
    { 
        path: '/en/artists/:url', 
        name: 'Artist Profile En',
        component: Profile,
        props: true
    },
    { 
        path: '/en/contact-us', 
        name: 'Contact En',
        component: Contact
    },
    { 
        path: '/contacto', 
        name: 'Contacto',
        component: Contact
    },
    { 
        path: '/en/about-us', 
        name: 'AboutUs En',
        component: About
    },
    { 
        path: '/sobre-nosotros', 
        name: 'SobreNosotros',
        component: About
    },
    { 
        path: '/en/concepts', 
        name: 'Concepts En',
        component: CategoryPage
    },
    { 
        path: '/conceptos', 
        name: 'Conceptos',
        component: CategoryPage
    },
    { 
        path: '/en/concepts/:url', 
        name: 'Concept Profile En',
        component: Concept
    },
    { 
        path: '/conceptos/:url', 
        name: 'Conceptos Perfil',
        component: Concept
    }
        
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
})

// Language localization
router.beforeEach((to) => {
    i18n.locale=getRouteLocale(to.path);
})

// Get route locale
function getRouteLocale(url) {
    let splitURL = url.split('/')
    let locale = "es";
    if(splitURL[1] == "en") {
        locale = "en";
    } // endif
    return locale;
}


//Vue Mixin
const globalMixin = {
    data(){
      return {
        homeData: homeData,
        footerData : footerData,
        navData : navData,
        contactData : contactData,
        aboutData : aboutData,
        profileData: profileData,
        conceptData: conceptData,
        artistsData: artistsData,
        conceptsData: conceptsData,
        apiMode : false,
        apiURLBase : "https://rumblefish-api.herokuapp.com", // "http://localhost:1337" / https://rumblefish-api.herokuapp.com
        lang :  { "iso" : "es", "path" : "/" } // Spanish is Default
      };
    },
    methods : {
        sortABC : function (data) {
            data.sort((a, b) => a.name.localeCompare(b.name));
            return data;
        }
    }
};


// Run app!
const app = createApp(App)
app.mixin(globalMixin)
app.use(i18n)
app.use(router)
app.mount('#app')